import React from "react"
import { graphql } from "gatsby"
import Wrapper from "../components/Wrapper"
import Grid from "../components/Grid"
import { Helmet } from "react-helmet"
import get from "lodash/get"
import { RichText } from "prismic-reactjs"
import GuideComponent from "../components/Guide"

export const query = graphql`
  query {
    prismic {
      allShops {
        edges {
          node {
            sizes_guidance {
              sizes_guidance_description
              sizes_guidance_title
            }
          }
        }
      }
    }
  }
`

const Tilbud = ({ data }) => {
  const doc = data.prismic.allShops.edges.slice(0, 1).pop()
  if (!doc) return null
  const guidance = get(data, "prismic.allShops.edges[0]")
  const lang = 'da-dk'
  let title = get(
    guidance,
    "node.sizes_guidance[0].sizes_guidance_title[0].text"
  )

  let guidanceStepsObj = get(
    guidance,
    "node.sizes_guidance[0].sizes_guidance_description"
  )

  let guidanceStepsFormatted = null

  if (guidanceStepsObj) {
    guidanceStepsFormatted = RichText.render(guidanceStepsObj)
    console.log(guidanceStepsObj)
    console.log(guidanceStepsFormatted)
  }

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://paperform.co/__embed"
        ></script>
      </Helmet>
      <header className="mb--xxl">
        <div className="samples-header-inner">
          <h1 class="fs--xl lh--s">
            Send dine oplysninger til os, så vender vi retur med et tilbud
          </h1>
        </div>
      </header>
      <div className="slice">
        <Wrapper>
          <Grid>
            <div className="text-col">
              <h2 class="fs--m">Udfyld dine oplysninger</h2>
              <br />
              <br />
              <div data-paperform-id="kapqrrhv"></div>
            </div>
            <div className="text-col tilbud-guidance">
              <h2 class="fs--m">Sådan måler du højde og bredde</h2>
              <GuideComponent lang={lang} />

              <br />
              <br />
              <div>{guidanceStepsFormatted}</div>
            </div>
          </Grid>
        </Wrapper>
      </div>
    </>
  )
}

export default Tilbud
